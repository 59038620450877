<style lang="scss" scoped>
@import "../styles/section";
.food {
  .section-header {
    background-image: url("https://ik.imagekit.io/kreatikart/patrol_paris/food-table-2-min.jpg");
    background-position: center 75%;
  }
  .section-content {
    .container {
      background-image: url("../assets/pattern-food.svg");
    }
    @media screen and (min-device-width: 900px) {
      bottom: 40px;
    }
  }
}
</style>

<template>
  <main class="section food">
    <section class="section-header"></section>
    <transition
      @before-enter="staggerBeforeEnter"
      @enter="staggerEnter"
      @leave="staggerLeave"
    >
      <section
        class="section-content stick"
        v-show="$store.getters['swiper/getIndex'] === 2"
      >
        <div class="container">
          <h1 class="section-title" data-stagger>patrol<span>food</span></h1>
          <p class="section-text" data-stagger>
            Dans une approche conviviale, simple, cool et abordable, le chef du
            Patrol prend plaisir à cuisiner devant ses clients. Un veritable
            moment de partage et de plaisir !
          </p>
          <div class="section-actions" data-stagger>
            <!--            <router-link class="base-btn primary" :to="{ name: 'WeeklyBrunch' }"-->
            <!--              >Weekly brunch</router-link-->
            <!--            >-->
            <!--            <router-link-->
            <!--              class="base-btn primary"-->
            <!--              :to="{ name: 'WeekendBrunch' }"-->
            <!--              >Weekend brunch</router-link-->
            <!--            >-->
            <router-link class="base-btn primary" :to="{ name: 'Breakfast' }"
              >Breakfast</router-link
            >
          </div>
        </div>
      </section>
    </transition>
  </main>
</template>

<script>
import { stagger } from "@/js/gsap-transitions-hooks";

export default {
  name: "Food",
  computed: {
    getSwiperProgress() {
      return this.$store.getters["getSwiperProgress"];
    }
  },
  methods: {
    ...stagger
  }
};
</script>
